@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
*::after,
*::before {
  box-sizing: border-box;
}
ul {
  padding: 0;
}
li {
  list-style: none;
}
p {
  margin-bottom: 0;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}

body {
  position: relative;
  background-color: #fff;
}

body::after {
  content: "";
  height: 60px;
  width: 80px;
  position: absolute;
  top: 20px;
  left: 30px;
  /* background-image: url("https://pixfar.developerachem.me/static/media/logo.56e36e3eb3e9fa484996.png"); */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-color: #008eff; */
  border-radius: 10px;
}

.custom_body {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #222222;
  color: #9d9d9d;
  padding: 5px;
  font-family: roboto;
  font-size: 14px;
}

.main_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 80%;
}

.all_button h2 {
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 10px;
}

.all_button button {
  padding: 10px 20px;
  margin-right: 5px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.bg_primary {
  background-color: #008eff;
  color: white;
  padding: 10px 20px !important;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
}

.body_button p {
  color: gray;
  font-size: 10;
  font-size: 15px;
  font-weight: 300;
}

.welcome {
  text-align: center;
}
.welcome img {
  width: 200px;
}

.welcome h2 {
  font-size: 35px;
  font-weight: 400;
}

.form-group {
  margin-bottom: 8px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus {
  outline: none;
  border-color: #8cb4c4;
}

.error-message {
  display: block;
  color: #f00;
  font-size: 14px;
  margin-top: 5px;
}

.submit-button {
  display: inline-block;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0069d9;
}

.submit-button:active {
  background-color: #005cbf;
}

.mainHeader {
  background-color: #328eff;
  position: fixed;
  top: 0;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 80%;
  margin: auto;
}

.header_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header_right h2 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.header_right img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.logo {
  display: flex;
  align-items: center;
  gap: 2px;
}

.logo img {
  width: 30px;
}

.logo h1 {
  margin-bottom: -8px;
  color: white;
}

.body_button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.join_group {
  display: flex;
  /* align-items: center; */
  gap: 5px;
}

.join_group button {
  border: 1px solid #ddd;
  background-color: #328eff;
  color: white;
}

/* Responsive Css */
@media only screen and (max-width: 900px) {
  .main_body {
    width: 95%;
  }
  .all_button h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  body::after {
    height: 50px;
    width: 60px;
  }
  .main_body {
    display: flex;
    flex-direction: column-reverse;
  }
  .all_button {
    text-align: center;
  }
  .all_button h2 {
    font-size: 25px;
  }
  .banner img {
    width: 300px;
  }
}

@media only screen and (max-width: 400px) {
  .all_button h2 {
    font-size: 20px;
  }
  .banner img {
    width: 200px;
  }
  .all_button p {
    font-size: 14px;
  }
}
